import React, { useEffect } from "react"
import loadable from "@loadable/component"
import useComponentsGenerator from "@hooks/useComponentsGenerator"
import useWindowDimensions from "@hooks/useWindowDimensions"
import { IMAGES_MOBILE } from "../../constants"

const TeamSectionItem = loadable(() => import("../../components/TeamSectionItem"));

const useTeamSectionConfiguration = () => {
  const { isDesktop } = useWindowDimensions();
  const [componentElements, generateComponents] = useComponentsGenerator(IMAGES_MOBILE);

  useEffect(() => {
    generateComponents((image, index) => componentElements.push(<TeamSectionItem images={image} key={index} />))
  }, []);

  return {
    isDesktop,
    componentElements
  };

};

export default useTeamSectionConfiguration;
