import React from "react"
import loadable from "@loadable/component"
import { useTeamSectionConfiguration } from "./hooks";
import { LayoutWrapper } from "../../../../styles/CommonStyles"
import { 
    PAGE_HEADING_TITLE,
    CAREERS_IMAGES_COLLAGE_CLASSNAME,
    CAREERS_IMAGES_COLLAGE_CONTAINER_CLASSNAME
} from "./constants"
import { StyledCollageWrapper } from "./TeamSection.style"

const PageHeading = loadable(() => import("@common/PageHeading/PageHeading"));
const Desktop = loadable(() => import("./components/Desktop"));

const TeamSection = () => {
  const { isDesktop, componentElements } = useTeamSectionConfiguration();
  return (
    <LayoutWrapper>
      <StyledCollageWrapper>
        <PageHeading heading={PAGE_HEADING_TITLE} />
        {!isDesktop ? (
          <div className={CAREERS_IMAGES_COLLAGE_CLASSNAME}>
            <div className={CAREERS_IMAGES_COLLAGE_CONTAINER_CLASSNAME}>
              {componentElements}
            </div>
          </div>
        ) : (
          <Desktop />
        )}
      </StyledCollageWrapper>
    </LayoutWrapper>
  )
}

export default TeamSection;
