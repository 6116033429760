import styled from "styled-components";
import { TABLET_MAX_WIDTH, MOBILE_MIN_WIDTH } from "@constants";

export const StyledCollageWrapper = styled.div`
    padding: 5rem 0;

    & .page-heading__heading {
        margin-bottom: 2.5rem;
    }

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        padding: 2.5rem 0;

        .careers-images-collage {
            height: 17.25rem;

            &__container {
                padding-right: 6.25rem;
                column-gap: 2rem;
            }

            &__image {
                width: 13rem;
                height: 17.25rem;

                &-container {
                    width: 13rem;
                    height: 17.25rem;
                    border: 0.0625rem solid #876e62;
                }
            }
        }
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        & .page-heading__heading {
            margin-bottom: 1.5rem;
        }

        .careers-images-collage {
            &__container {
                padding-right: 0;
                column-gap: 1rem;
            }
        }
    }

    & .careers-images-collage {
        & img {
            object-fit: cover;
            object-position: center;
        }

        &__column-left {
            row-gap: 2.5rem;

            & .careers-images-collage__image-container {
                &:first-child,
                &:first-child img {
                    height: 35rem;
                    width: 26.375rem;
                }

                &:nth-child(2),
                &:nth-child(2) img {
                    height: 17.5rem;
                    width: 26.375rem;
                }
            }
        }

        &__column-right-below {
            row-gap: 2.5rem;

            & .careers-images-collage__image-container {
                &:first-child,
                &:first-child img {
                    height: 23.75rem;
                    width: 40.75rem;
                }
            }

            &-bottom {
                & .careers-images-collage__image-container {
                    &:first-child,
                    &:first-child img {
                        height: 28.75rem;
                        width: 19.125rem;
                    }

                    &:nth-child(2),
                    &:nth-child(2) img {
                        height: 28.75rem;
                    }
                }
            }
        }
    }
`;
