import React, { useCallback, useEffect, useState } from "react"
import loadable from "@loadable/component"
import useWindowDimensions from "@hooks/useWindowDimensions"
import useComponentsGenerator from "@hooks/useComponentsGenerator"

const Image = loadable(() => import("@common/Image/Image"))

const useTeamSectionItemConfiguration = images => {
  const { isDesktop } = useWindowDimensions();
  const [componentElements, generateComponents] = useComponentsGenerator(images.length ? images : []);
  const [imageNumber, setImageNumber] = useState(0);

  const changeImage = useCallback(() => {
    // images change in a circle
    setImageNumber(prevNumber => (prevNumber + 1) % images.length)
  }, [images, imageNumber])

  const generateSrcSet = useCallback(image => `${image.srcSet[0]} 2x, ${image.srcSet[1]} 3x`,[]);

  useEffect(() => {
    generateComponents((image, index) => {
      componentElements.push(
        <Image
          src={image.src}
          srcSet={generateSrcSet(image)}
          alt={image.alt}
          key={index}
        />
      )
    })
  }, [])

  return {
    isDesktop,
    imageNumber,
    componentElements,
    changeImage,
  }
}

export default useTeamSectionItemConfiguration
