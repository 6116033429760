import team1Desktop1x from "@images/about-us/team1Desktop1x.jpg";
import team1Desktop2x from "@images/about-us/team1Desktop2x.jpg";
import team1Desktop3x from "@images/about-us/team1Desktop3x.jpg";
import team2Desktop1x from "@images/about-us/team2Desktop1x.jpg";
import team2Desktop2x from "@images/about-us/team2Desktop2x.jpg";
import team2Desktop3x from "@images/about-us/team2Desktop3x.jpg";
import team3Desktop1x from "@images/about-us/team3Desktop1x.jpg";
import team3Desktop2x from "@images/about-us/team3Desktop2x.jpg";
import team3Desktop3x from "@images/about-us/team3Desktop3x.jpg";
import team4Desktop1x from "@images/about-us/team4Desktop1x.jpg";
import team4Desktop2x from "@images/about-us/team4Desktop2x.jpg";
import team4Desktop3x from "@images/about-us/team4Desktop3x.jpg";
import team5Desktop1x from "@images/about-us/team5Desktop1x.jpg";
import team5Desktop2x from "@images/about-us/team5Desktop2x.jpg";
import team5Desktop3x from "@images/about-us/team5Desktop3x.jpg";
import team6Desktop1x from "@images/about-us/team6Desktop1x.jpg";
import team6Desktop2x from "@images/about-us/team6Desktop2x.jpg";
import team6Desktop3x from "@images/about-us/team6Desktop3x.jpg";
import team7Desktop1x from "@images/about-us/team7Desktop1x.jpg";
import team7Desktop2x from "@images/about-us/team7Desktop2x.jpg";
import team7Desktop3x from "@images/about-us/team7Desktop3x.jpg";
import team8Desktop1x from "@images/about-us/team8Desktop1x.jpg";
import team8Desktop2x from "@images/about-us/team8Desktop2x.jpg";
import team8Desktop3x from "@images/about-us/team8Desktop3x.jpg";
import team9Desktop1x from "@images/about-us/team9Desktop1x.jpg";
import team9Desktop2x from "@images/about-us/team9Desktop2x.jpg";
import team9Desktop3x from "@images/about-us/team9Desktop3x.jpg";
import team10Desktop1x from "@images/about-us/team10Desktop1x.jpg";
import team10Desktop2x from "@images/about-us/team10Desktop2x.jpg";
import team10Desktop3x from "@images/about-us/team10Desktop3x.jpg";
import team11Desktop1x from "@images/about-us/team11Desktop1x.jpg";
import team11Desktop2x from "@images/about-us/team11Desktop2x.jpg";
import team11Desktop3x from "@images/about-us/team11Desktop3x.jpg";
import team12Desktop1x from "@images/about-us/team12Desktop1x.jpg";
import team12Desktop2x from "@images/about-us/team12Desktop2x.jpg";
import team12Desktop3x from "@images/about-us/team12Desktop3x.jpg";
import team13Desktop1x from "@images/about-us/team13Desktop1x.jpg";
import team13Desktop2x from "@images/about-us/team13Desktop2x.jpg";
import team13Desktop3x from "@images/about-us/team13Desktop3x.jpg";
import team14Desktop1x from "@images/about-us/team14Desktop1x.jpg";
import team14Desktop2x from "@images/about-us/team14Desktop2x.jpg";
import team14Desktop3x from "@images/about-us/team14Desktop3x.jpg";
import team15Desktop1x from "@images/about-us/team15Desktop1x.jpg";
import team15Desktop2x from "@images/about-us/team15Desktop2x.jpg";
import team15Desktop3x from "@images/about-us/team15Desktop3x.jpg";
import team16Desktop1x from "@images/about-us/team16Desktop1x.jpg";
import team16Desktop2x from "@images/about-us/team16Desktop2x.jpg";
import team16Desktop3x from "@images/about-us/team16Desktop3x.jpg";
import team17Desktop1x from "@images/about-us/team17Desktop1x.jpg";
import team17Desktop2x from "@images/about-us/team17Desktop2x.jpg";
import team17Desktop3x from "@images/about-us/team17Desktop3x.jpg";
import team18Desktop1x from "@images/about-us/team18Desktop1x.jpg";
import team18Desktop2x from "@images/about-us/team18Desktop2x.jpg";
import team18Desktop3x from "@images/about-us/team18Desktop3x.jpg";
import team19Desktop1x from "@images/about-us/team19Desktop1x.jpg";
import team19Desktop2x from "@images/about-us/team19Desktop2x.jpg";
import team19Desktop3x from "@images/about-us/team19Desktop3x.jpg";
import team1Mobile from "@images/about-us/team1Mobile.jpg";
import team2Mobile from "@images/about-us/team2Mobile.jpg";
import team3Mobile from "@images/about-us/team3Mobile.jpg";
import team4Mobile from "@images/about-us/team4Mobile.jpg";
import team5Mobile from "@images/about-us/team5Mobile.jpg";
import team6Mobile from "@images/about-us/team6Mobile.jpg";
import team7Mobile from "@images/about-us/team7Mobile.jpg";
import team8Mobile from "@images/about-us/team8Mobile.jpg";
import team9Mobile from "@images/about-us/team9Mobile.jpg";

export const PAGE_HEADING_TITLE = "Team";
export const CAREERS_IMAGES_COLLAGE_CLASSNAME = "careers-images-collage";
export const CAREERS_IMAGES_COLLAGE_IMAGE_CLASSNAME = "careers-images-collage__image";
export const CAREERS_IMAGES_COLLAGE_CONTAINER_CLASSNAME = "careers-images-collage__container";
export const CAREERS_IMAGES_COLLAGE_IMAGE_CONTAINER = "careers-images-collage__image-container";
export const CAREERS_IMAGES_COLLAGE_COLUMN_LEFT_CLASSNAME = "careers-images-collage__column-left";
export const CAREERS_IMAGES_COLLAGE_COLUMN_RIGHT_BELOW_CLASSNAME = "careers-images-collage__column-right-below";
export const CAREERS_IMAGES_COLLAGE_COLUMN_RIGHT_BELOW_BOTTOM_CLASSNAME = "careers-images-collage__column-right-below-bottom";

export const IMAGES_DESKTOP = [
    [
        {
            src: team1Desktop1x,
            srcSet: [team1Desktop2x, team1Desktop3x],
            alt: "Seasoned front-end developers",
        },
        {
            src: team2Desktop1x,
            srcSet: [team2Desktop2x, team2Desktop3x],
            alt: "Discovery phase for software development",
        },
    ],
    [
        {
            src: team9Desktop1x,
            srcSet: [team9Desktop2x, team9Desktop3x],
            alt: "Impressit co-founders speech",
        },
        {
            src: team8Desktop1x,
            srcSet: [team8Desktop2x, team8Desktop3x],
            alt: "Mobile app developer",
        },
        {
            src: team11Desktop1x,
            srcSet: [team11Desktop2x, team11Desktop3x],
            alt: "Scrum meeting",
        },
        {
            src: team14Desktop1x,
            srcSet: [team14Desktop2x, team14Desktop3x],
            alt: "Monthly plan meeting",
        },
    ],
    [
        {
            src: team18Desktop1x,
            srcSet: [team18Desktop2x, team18Desktop3x],
            alt: "Software development experts",
        },
        {
            src: team7Desktop1x,
            srcSet: [team7Desktop2x, team7Desktop3x],
            alt: "Software development experts",
        },
        {
            src: team16Desktop1x,
            srcSet: [team16Desktop2x, team16Desktop3x],
            alt: "Software development experts",
        },
        {
            src: team10Desktop1x,
            srcSet: [team10Desktop2x, team10Desktop3x],
            alt: "Software development company",
        },
        {
            src: team12Desktop1x,
            srcSet: [team12Desktop2x, team12Desktop3x],
            alt: "Agile software development team",
        },
        {
            src: team13Desktop1x,
            srcSet: [team13Desktop2x, team13Desktop3x],
            alt: "Planning for software development",
        },
    ],
    [
        {
            src: team15Desktop1x,
            srcSet: [team15Desktop2x, team15Desktop3x],
            alt: "Software development",
        },
        {
            src: team3Desktop1x,
            srcSet: [team3Desktop2x, team3Desktop3x],
            alt: "Trusted software development partner",
        },
        {
            src: team4Desktop1x,
            srcSet: [team4Desktop2x, team4Desktop3x],
            alt: "Seasoned project manager",
        },
    ],
    [
        {
            src: team17Desktop1x,
            srcSet: [team17Desktop2x, team17Desktop3x],
            alt: "Web app developer",
        },
        {
            src: team19Desktop1x,
            srcSet: [team19Desktop2x, team19Desktop3x],
            alt: "Web app developer",
        },
        {
            src: team5Desktop1x,
            srcSet: [team5Desktop2x, team5Desktop3x],
            alt: "Web app developer",
        },
        {
            src: team6Desktop1x,
            srcSet: [team6Desktop2x, team6Desktop3x],
            alt: "Impressit co-founders",
        },
    ],
];

export const IMAGES_MOBILE = [
    {
        src: team1Mobile,
        alt: "Seasoned front-end developers",
    },
    {
        src: team2Mobile,
        alt: "Discovery phase for software development",
    },
    {
        src: team3Mobile,
        alt: "Trusted software development partner",
    },
    {
        src: team4Mobile,
        alt: "Seasoned project manager",
    },
    {
        src: team5Mobile,
        alt: "Web app developer",
    },
    {
        src: team6Mobile,
        alt: "Software development experts",
    },
    {
        src: team7Mobile,
        alt: "Mobile app developer",
    },
    {
        src: team8Mobile,
        alt: "Scrum meeting",
    },
    {
        src: team9Mobile,
        alt: "Impressit co-founders",
    },
];