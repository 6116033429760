import { useRef } from "react";

export default function useComponentsGenerator(componentsInfo) {
    const componentElements = useRef([]).current;

    const generateComponents = handleFunc => {
        componentElements.length = 0;

        componentsInfo.forEach(handleFunc);

        return componentElements;
    };

    return [componentElements, generateComponents];
}
