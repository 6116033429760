import styled, { css } from "styled-components";
import { TABLET_MAX_WIDTH, MOBILE_MIN_WIDTH } from "@constants";

export const MainTitle = styled.h1`
    font-size: 4rem;
    line-height: 4.8rem;
    letter-spacing: 0.01em;
    margin: 0 0 2.5rem 0;

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        font-weight: 700;
        font-size: 2.5rem;
        line-height: 3rem;
        margin: 0 0 1.5rem 0;
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        font-size: 2rem;
        line-height: 2.5rem;
    }
`;

export const SecondaryTitle = styled.h2`
    font-size: 2.5rem;
    line-height: 3rem;
    letter-spacing: 0.02em;
    margin: 0 0
        ${props =>
            Array.isArray(props.margins) ? props.margins[0] : props.margins}px
        0;

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        font-size: 2rem;
        line-height: 2.5rem;
        margin: 0 0
            ${props =>
                Array.isArray(props.margins)
                    ? props.margins[1]
                    : props.margins}px
            0;
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        font-size: 1.5rem;
        line-height: 2rem;
        margin: 0 0
            ${props =>
                Array.isArray(props.margins)
                    ? props.margins[2]
                    : props.margins}px
            0;
    }
`;

export const Description = styled.p`
    font-size: 1rem;
    line-height: 1.5rem;
    margin-top: 2.5rem;
    letter-spacing: 0.05em;
    color: rgba(255, 255, 255, 0.6);
    margin-bottom: ${props => (props.margin ? props.margin : 0)}rem;

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        margin-top: 1.5rem;
    }
`;

export const ButtonWrapper = styled.a`
    text-decoration: none;
    ${props =>
        props.isSecondButton &&
        css`
            display: flex;

            @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
                flex-direction: column;
            }
        `}

    & button {
        display: flex;
        align-items: center;
        justify-content: ${props => (props.isCentered ? "center" : "")};
        width: ${props => (props.width ? props.width : "")};
        color: ${props =>
            props.isWhite ? "#fff" : "rgba(255, 255, 255, 0.8)"};
        height: 3.5rem !important;
        font-size: 1.125rem !important;
    }
`;

export const LayoutWrapper = styled.div`
    max-width: 69.625rem;
    margin: 0 auto;
    padding: 0 10.1875rem;

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        padding: 0 6.25rem;
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        padding: 0 1.5rem;
    }
`;
